import React from "react"
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Navbar from "./Navbar"
import Menu from "./Menu"
import Footer from "./Footer"
import { FloatButton } from 'antd'
// import { loadLiveChat } from '@/utils/liveChat';
import { loadJekka } from '@/utils/jekka';
import { loadGtm } from '@/utils/gtm';
import { loadMatomo } from "@/utils/matomo";
import { loadForter } from "@/utils/forter";
import { abTestCalculate } from "@/utils/ABtest";

interface LayoutProps {
  children: React.ReactNode;
  wideScreen?: boolean;  // 是否使用宽屏
  notStickyNavbar?: boolean; // 是否取消Navbar吸顶效果
}

export default function DefaultLayout(props: LayoutProps) {
  const router = useRouter();

  useEffect(() => {
    function handleWindowLoad() {
      // 排除详情页，加载LiveChat
      // if (!router.pathname.includes('/product-detail/')) {
      //   loadLiveChat();
      // }
      loadJekka();
      loadMatomo();
      loadGtm();
      loadForter();
      // 加载AB测试逻辑
      abTestCalculate("")
    }
    
    window.addEventListener('load', handleWindowLoad);

    return () => {
      window.removeEventListener('load', handleWindowLoad);
    };
  }, []);

  return (
    <div>
      <Navbar
        wideScreen={props.wideScreen}
        useChristmasTheme={router.pathname.includes('/myaccount') ? false : false}
        sticky={props.notStickyNavbar ? false : true}
      />

      <Menu 
        wideScreen={props.wideScreen}
        useChristmasTheme={router.pathname.includes('/myaccount') ? false : false} 
      />

      <main style={{ background: '#F5F5F5' }}>{props.children}</main>

      <Footer />
      <FloatButton.BackTop />
    </div>
  )
}